import React from "react"
import { Layout,Content } from "src/components/common"

const LegalPage = () => (
  <Layout>
    <Content content="legal" />
  </Layout>
)

export default LegalPage
